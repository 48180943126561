import { ethers } from "ethers";
import multicallAbi from '../abis/multicall.json';
import presaleAbi from '../abis/presale.json';
import stakeAbi from '../abis/stake.json';
import { getWeb3 } from "./functions";
import { MULTICALL, PRESALE_ADDRESS, STAKE_ADDRESS, TOKEN_DECIMALS } from "./constant";

export const getContract = (abi, address, library) => {
  try {
    return new ethers.Contract(address, abi, library)
  }
  catch {
    return false;
  }
}

export const getWeb3Contract = (abi, address) => {
  let web3 = getWeb3();
  return new web3.eth.Contract(abi, address);
}


export const getMultiCall = async (calls = []) => {
  let web3 = getWeb3();
  const mc = new web3.eth.Contract(multicallAbi, MULTICALL);
  const callRequests = calls.map((call) => {
    const callData = call.encodeABI();
    return {
      target: call._parent._address,
      callData,
    };
  });

  const { returnData } = await mc.methods
    .aggregate(callRequests)
    .call({});

  let finalData = returnData.map((hex, index) => {
    const types = calls[index]._method.outputs.map((o) =>
      o.internalType !== o.type && o.internalType !== undefined ? o : o.type
    );

    let result = web3.eth.abi.decodeParameters(types, hex);

    delete result.__length__;

    result = Object.values(result);

    return result.length === 1 ? result[0] : result;
  });

  return finalData;
}


export const getTokenAmount = async (amount = '', decimals = 18) => {
  try {
    let presaleContract = getWeb3Contract(presaleAbi, PRESALE_ADDRESS);
    let response = await presaleContract.methods.getTokenAmount(ethers.utils.parseUnits(amount.toString(), decimals)).call();

    return parseFloat(response) / Math.pow(10, TOKEN_DECIMALS);
  }
  catch (err) {
    console.log(err.message)
    return 0;
  }
}

export const getStaticTokenAmount = (rate, amount = '') => {
  try {
    if (rate === 0) return 0;
    let amtOut = (amount / rate );
    return amtOut;
  }
  catch (err) {
    return 0;
  }
}

export const getEstimatedReward = async (amount = '') => {
  try {
    let stakeContract = getWeb3Contract(stakeAbi, STAKE_ADDRESS);
    let response = await stakeContract.methods.checkDividend(ethers.utils.parseUnits(amount.toString(), TOKEN_DECIMALS)).call();
    return parseFloat(response) / Math.pow(10, 18);
  }
  catch (err) {
    console.log(err.message)
    return 0;
  }
}